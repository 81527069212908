import React from 'react'

import Layout from '../components/Layout'

export default function NowPage() {
  return (
    <Layout>
      <h1 className="text-5xl">What I'm Doing Now?</h1>
      <p className="my-8 text-lg">
        I'm currently working remotely for{' '}
        <a href="https://www.everlane.com">Everlane</a>.
        <br />
        At Everlane, we build a more ethical, transparent, and sustainable
        fashion retail brand.
        <br />I really enjoy our thight-knit group and excited where we are
        heading next.
      </p>
      <p className="mt-4 text-lg">
        On my personal time, nowadays I enjoy reading classic books. Here are
        some titles that I read or interested in reading soon.
      </p>
      <ul className="text-base list-disc ml-5">
        <li>1984 — Geore Orwell</li>
        <li>The Picture of Dorian Gray — Oscar Wilde</li>
        <li>Outliers — Malcolm Gladwell</li>
        <li>The War of Art — Steven Pressfield</li>
        <li>Big Magic — Elizabeth Gilbert</li>
      </ul>
    </Layout>
  )
}
